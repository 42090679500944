import { useEffect, useState } from "react";
import useQuery from "../hooks/useQuery";
import { FicheClientApiData } from "../interfaces/ficheClient.interface";
import ficheClientMapping from "../utils/FicheClientMapping";
import { getUrlParameter } from "../utils/urlParametersUtils";
import { Conf, useConfFetch } from "./useConfFetch";
import { Error, HttpError } from "../enum/error.enum";
import { useOktaAuth } from "@okta/okta-react";

const DEFAULT = {
  data: [],
  isLoading: false,
  isError: false,
  error: {},
};

const useGetFicheClient = () => {
  const [conf, err, loading] = useConfFetch();
  const [datascienceApiUrl, setDatascienceApiUrl] = useState("");
  const [adbUrl, setAdbUrl] = useState("");
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!loading && !err && conf) {
      const datascienceApiUrl = (conf as Conf)
        .REACT_APP_DATASCIENCE_API_URL as string;
      const adbUrl = (conf as Conf).REACT_APP_ADB_URL as string;
      setDatascienceApiUrl(datascienceApiUrl);
      setAdbUrl(adbUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conf]);

  const bearerTokenStorage = localStorage.getItem("okta-token-storage");
  if (!bearerTokenStorage) {
    console.error(Error.AUTH_TOKEN_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.AUTH_TOKEN_NOT_FOUND,
      },
    };
  }
  const bearerToken = JSON.parse(bearerTokenStorage)?.accessToken.accessToken;
  if (!bearerToken) {
    console.error(Error.AUTH_TOKEN_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.AUTH_TOKEN_NOT_FOUND,
      },
    };
  }

  const phoneNumber = getUrlParameter("param");
  if (!phoneNumber) {
    console.error(Error.PHONE_NUMBER_NOT_FOUND);
    return {
      ...DEFAULT,
      isError: true,
      error: {
        message: Error.PHONE_NUMBER_NOT_FOUND,
      },
    };
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, isLoading, isError, error } = useQuery<FicheClientApiData>(
    `${datascienceApiUrl}/customer_summary/phone_number/${phoneNumber}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    }
  );

  // Sign out if the bearer token is invalid
  if (error?.message === HttpError.UNAUTHORIZE) {
    oktaAuth.signOut();
  }

  return {
    data: ficheClientMapping(data, adbUrl),
    isLoading,
    isError,
    error,
  };
};

export default useGetFicheClient;
