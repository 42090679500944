export enum UnitType {
  PARKING = "PARKING",
  OTHER = "OTHER",
  APARTMENT = "APARTMENT",
  HOUSING = "HOUSING",
}

export const unitTypeLabel = {
  [UnitType.PARKING]: "Parking",
  [UnitType.OTHER]: "Autre",
  [UnitType.APARTMENT]: "Appartement",
  [UnitType.HOUSING]: "Logement",
};
