import React, { Suspense } from "react";
import "./Home.scss";
import Grid from "@mui/material/Unstable_Grid2";
import HomeSkeleton from "../skeleton/Homeskeleton";
import useGetFicheClient from "../../hooks/useGetFicheClient";

const TopPresentation = React.lazy(
  () => import("../top-presentation/TopPresentation")
);
const CustomerList = React.lazy(() => import("../customer-list/CustomerList"));

export default function Home() {
  const { data, isLoading, isError } = useGetFicheClient();
  return (
    <main>
      <Suspense>
        <Grid
          className="main-grid"
          sx={{ backgroundColor: "grey.300" }}
          container
          spacing={2}
        >
          <Grid xs={12} lgOffset={1} lg={10} md={12} xl={8} xlOffset={2} p={3}>
            {isLoading ? (
              <HomeSkeleton />
            ) : data && !isError ? (
              <div>
                <TopPresentation props={data} />
                <CustomerList props={data} />
              </div>
            ) : (
              <div>
                <h1>Bienvenue sur l'application Fiche Client pour 8x8</h1>
              </div>
            )}
          </Grid>
        </Grid>
      </Suspense>
    </main>
  );
}
