/**
 * Retrieve a param url from key
 * @param {string} key - Param key we want to retrieve
 * @returns {string | null}
 */
export function getUrlParameter(key: string): string | null {
  const searchParams = new URLSearchParams(
    new URL(window.location.href).search.replace("+", "%2B")
  );
  const paramValue = searchParams.get(key);
  return paramValue ? encodeURI(paramValue) : null;
}
