import { Profil, profileTypeUrl } from "../enum/profil.enum";
import { TicketStatus } from "../enum/ticket.enum";
import { UnitType, unitTypeLabel } from "../enum/unit.enum";
import {
  CoOwnerUnitsList,
  CustomerTicket,
  Entity,
  FicheClientApiData,
  LessorUnitsList,
  MappedAcount,
  MappedFicheClient,
  Mission,
  PaymentType,
  TenantUnitsList,
  UnitsList,
} from "../interfaces/ficheClient.interface";

/**
 * Format data to structured data
 * @param {FicheClientApiData[]} rawData - Raw data from api
 * @param {string} baseUrl - Base url to build link
 * @returns {MappedFicheClient[]}
 */
export default function ficheClientMapping(
  rawData: FicheClientApiData[],
  baseUrl: string
): MappedFicheClient[] {
  return rawData.flatMap((data: FicheClientApiData) => {
    const profil = getProfile(data);
    return data.entities_list.reduce(
      (accumulator: MappedFicheClient[], entity: Entity) => {
        const mappedAccount = {
          informations: {
            uuid: entity.usage_id,
            civility: entity.civility,
            name: entity.last_name,
            firstname: entity.first_name,
            lastname: entity.last_name,
            birthdate: entity.birth_date,
            email: entity.email,
            phoneNumbers: [...Object.values(entity.phone)].filter(
              (number) => number !== undefined
            ),
            myFonciaStatut: entity.myfoncia_details?.myfonciaIsActive,
            url: getFicheAdbLink(baseUrl, entity),
          },
          accounts: buildAccounts(data, profil, entity, baseUrl),
        };

        // Remove fiche client without account
        if (mappedAccount.accounts.size > 0) {
          accumulator.push(mappedAccount);
        }

        return accumulator;
      },
      []
    );
  });
}

/**
 * Build accounts levels
 * @param {FicheClientApiData} data - Raw data
 * @param {Profil[]} profil - Profil type
 * @param {Entity} entity - Linked Entity to Account
 * @param {string} baseUrl - Base url to build link
 * @returns {Map<string, MappedAcount[]>}
 */
function buildAccounts(
  data: FicheClientApiData,
  profil: Profil[],
  entity: Entity,
  baseUrl: string
): Map<string, MappedAcount[]> {
  const units: Map<string, MappedAcount[]>[] = [];
  if (profil.includes(Profil.CO_OWNER)) {
    const coOwnerUnits = mapUnits(
      data,
      data.co_owner_units_list,
      entity,
      Profil.CO_OWNER,
      (unit) => (unit as CoOwnerUnitsList).co_owner_account_id,
      (unit) =>
        `${(unit as CoOwnerUnitsList).building_address} ${
          (unit as CoOwnerUnitsList).building_city
        } ${(unit as CoOwnerUnitsList).building_zip_code}`,
      baseUrl
    );
    units.push(coOwnerUnits);
  }

  if (profil.includes(Profil.LESSOR)) {
    const lessorUnits = mapUnits(
      data,
      data.lessor_units_list,
      entity,
      Profil.LESSOR,
      (unit) => (unit as LessorUnitsList).lessor_account_id,
      (unit) =>
        `${(unit as LessorUnitsList).building_address} ${
          (unit as LessorUnitsList).building_city
        } ${(unit as LessorUnitsList).building_zip_code}`,
      baseUrl
    );
    units.push(lessorUnits);
  }

  if (profil.includes(Profil.TENANT)) {
    const tenantUnits = mapUnits(
      data,
      data.tenant_units_list,
      entity,
      Profil.TENANT,
      (unit) => (unit as TenantUnitsList).tenant_account_id,
      (unit) => (unit as TenantUnitsList).complete_address,
      baseUrl
    );
    units.push(tenantUnits);
  }

  const flattenUnits = units.flatMap((unit) => new Map(unit));
  return new Map<string, MappedAcount[]>(
    flattenUnits.flatMap((unit) => [...unit])
  );
}

/**
 * Build the account level
 * @param {FicheClientApiData} data
 * @param {Entity} entity
 * @param {string} baseUrl
 * @param {UnitsList} unit
 * @param {string} address
 * @param {Profil} profil
 * @returns {MappedAcount}
 */
function buildAccount(
  data: FicheClientApiData,
  entity: Entity,
  baseUrl: string,
  unit: UnitsList,
  address: string,
  profil: Profil
): MappedAcount {
  const entityId = entity.entity_id;
  const ticketsNumber = getTicketsCount(
    unit,
    profil,
    data.customer_ticket_list,
    entityId
  );
  const missionsNumber = getMissionsCount(
    profil,
    entityId,
    data.mission_list,
    unit
  );

  let account: MappedAcount = {
    address,
    profil,
    uuid: unit.unit_id,
    statut: unit.is_mandate_active ?? false,
    ticketsNumber,
    missionsNumber,
    url: getAccountAdbLink(baseUrl, entity, profil),
    paymentType: undefined,
    reminderLevel: undefined,
    lastAGDate: undefined,
    nextAGDate: undefined,
    properties: [],
  };

  if (profil === Profil.CO_OWNER || profil === Profil.TENANT) {
    const paymentType = entity.payment_type
      ?.map((payment: PaymentType) => payment.paymentType)
      .join(", ");

    account = {
      ...account,
      paymentType,
      reminderLevel: (unit as CoOwnerUnitsList | TenantUnitsList)
        .recovery_reminder_level,
      lastAGDate: (unit as CoOwnerUnitsList | TenantUnitsList)
        .last_general_assembly_date,
      nextAGDate: (unit as CoOwnerUnitsList | TenantUnitsList)
        .next_general_assembly_date,
      properties: [
        {
          id: unit.unit_id,
          type: translateUnitType(unit.unit_type),
          url: getUnitAdbLink(baseUrl, unit),
        },
      ],
    };
  }

  return account;
}

/**
 * Build the unit and store them by accountId to create a account level
 * @param {FicheClientApiData} data
 * @param {(UnitsList)[]} unitsList
 * @param {Entity} entity
 * @param {Profil} profil
 * @param {(unit: UnitsList) => string} getAccountId
 * @param {(unit: UnitsList) => string} getCompleteAddress
 * @param {string} baseUrl
 * @returns {Map<string, MappedAcount[]>}
 */
function mapUnits(
  data: FicheClientApiData,
  unitsList: UnitsList[],
  entity: Entity,
  profil: Profil,
  getAccountId: (unit: UnitsList) => string,
  getCompleteAddress: (unit: UnitsList) => string,
  baseUrl: string
): Map<string, MappedAcount[]> {
  return unitsList.reduce(
    (accumulator: Map<string, MappedAcount[]>, unit: UnitsList) => {
      if (unit.entity_id === entity.entity_id) {
        const accountId = getAccountId(unit);
        const item = accumulator.get(accountId);
        // If item exist, It means that an account with the same account_id exist. We store them in the same Key so we push the existing array
        // No need to create a new account because they share the same data
        // We only push new "bien" unit level
        if (item) {
          item[0].properties.push({
            id: unit.unit_id,
            type: translateUnitType(unit.unit_type),
            url: getUnitAdbLink(baseUrl, unit),
          });
        } else {
          // New key is created and account is built
          const mappedAccount = buildAccount(
            data,
            entity,
            baseUrl,
            unit,
            getCompleteAddress(unit),
            profil
          );
          accumulator.set(accountId, [mappedAccount]);
        }
      }
      return accumulator;
    },
    new Map()
  );
}

/**
 * Return Profil type depending on data
 * @param {FicheClientApiData} data - Raw data from api
 * @returns {Profil[]}
 */
function getProfile(data: FicheClientApiData): Profil[] {
  const profil = [];
  if (data.lessor_units_list.length > 0) {
    profil.push(Profil.LESSOR);
  }
  if (data.co_owner_units_list.length > 0) {
    profil.push(Profil.CO_OWNER);
  }
  if (data.tenant_units_list.length > 0) {
    profil.push(Profil.TENANT);
  }

  if (profil.length === 0) {
    console.error("Profil not found");
    return profil;
  }
  return profil;
}

/**
 * Build the account link for adb
 * @param {string} baseUrl
 * @param {Entity} entity
 * @param {Profil} profil
 * @returns
 */
function getAccountAdbLink(
  baseUrl: string,
  entity: Entity,
  profil: Profil
): string {
  return `${baseUrl}/customer/${entity.entity_id.replace("ML-CUSTOMER-", "")}/${
    profileTypeUrl[profil]
  }`;
}

/**
 * Build the fiche link for adb
 * @param {string} baseUrl
 * @param {UnitsLiEntityst} entity
 * @returns
 */
function getFicheAdbLink(baseUrl: string, entity: Entity): string {
  return `${baseUrl}/customer/${entity.entity_id.replace(
    "ML-CUSTOMER-",
    ""
  )}/dashboard`;
}

/**
 * Build the unit link for adb
 * @param {string} baseUrl
 * @param {UnitsList} unit
 * @returns
 */
function getUnitAdbLink(baseUrl: string, unit: UnitsList): string {
  return `${baseUrl}/unit/${unit.unit_id.replace("ML-UNIT-", "")}`;
}

/**
 * Return the number of tickets depending on profil
 * @param {UnitsList} unit
 * @param {Profil} profil
 * @param {CustomerTicket[]} customerTicketList
 * @param {string} entityId
 * @returns
 */
function getTicketsCount(
  unit: UnitsList,
  profil: Profil,
  customerTicketList: CustomerTicket[],
  entityId: string
): number {
  switch (profil) {
    case Profil.LESSOR:
      return customerTicketList.filter(
        (ticket) =>
          ticket.entity_id === entityId &&
          ticket.account_id === (unit as LessorUnitsList).lessor_account_id &&
          ticket.status !== TicketStatus.FINISHED
      ).length;
    case Profil.CO_OWNER:
      return customerTicketList.filter(
        (ticket) =>
          ticket.entity_id === entityId &&
          ticket.account_id ===
            (unit as CoOwnerUnitsList).co_owner_account_id &&
          ticket.status !== TicketStatus.FINISHED
      ).length;
    case Profil.TENANT:
      return customerTicketList.filter(
        (ticket) =>
          ticket.entity_id === entityId &&
          ticket.account_id === (unit as TenantUnitsList).tenant_account_id &&
          ticket.status !== TicketStatus.FINISHED
      ).length;
    default:
      return 0;
  }
}

/**
 * Return the number of missions depending on profil
 * @param {Profil} profil
 * @param {string} entityId
 * @param {Mission[]} missionList
 * @param {UnitsList} unit
 * @returns
 */
function getMissionsCount(
  profil: Profil,
  entityId: string,
  missionList: Mission[],
  unit: UnitsList
) {
  switch (profil) {
    case Profil.LESSOR:
      return missionList.filter(
        (mission) =>
          mission.entity_id === entityId &&
          mission.related_account_id ===
            (unit as LessorUnitsList).lessor_account_id
      ).length;
    case Profil.CO_OWNER:
      return missionList.filter(
        (mission) =>
          mission.entity_id === entityId &&
          mission.related_account_id ===
            (unit as CoOwnerUnitsList).co_owner_account_id
      ).length;
    case Profil.TENANT:
      return missionList.filter(
        (mission) =>
          mission.entity_id === entityId &&
          mission.related_account_id ===
            (unit as TenantUnitsList).tenant_account_id
      ).length;
    default:
      return 0;
  }
}

/**
 * Return the translated label of unit type
 * @param {unitType} unitType
 * @returns {string}
 */
function translateUnitType(unitType: UnitType): string {
  return unitTypeLabel[unitType] ?? unitType;
}
