import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

/**
 * Returns the skeleton of home page
 * @returns {JsxElement}
 */
export default function HomeSkeleton() {
  /**
   * Returns the skeleton of customer list
   * @returns {JsxElement}
   */
  const CustomerList = () => {
    const items = [];
    for (let i = 0; i < 3; i++) {
      items.push(
        <Box key={i} mb={1}>
          <Skeleton variant="rectangular" animation="wave" height={360} />
        </Box>
      );
    }

    return <>{items}</>;
  };

  return (
    <div>
      <Box>
        <Skeleton variant="rectangular" animation="wave" height={92} />
      </Box>
      <Box py={3} pl={0.5}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          width={200}
          height={30}
        />
      </Box>
      <CustomerList />
    </div>
  );
}
