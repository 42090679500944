import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import Home from "../components/home/Home";
import { ProtectedRoute } from "../components/routes/ProtectedRoute";
import Loader from "../components/common/loader/Loader";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loader />} />}
      />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="" element={<Home />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
