import React from "react";
import "./Loader.scss";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader() {
  return (
    <div id="loader">
      <CircularProgress />
    </div>
  );
}
