import { createTheme } from "@mui/material/styles";

const primaryColor = "#00ADB5";
const secondaryColor = "#FF7600";

// Create the theme using the custom colors
export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
});
