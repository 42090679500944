import { useState, useEffect } from "react";
import { ApiError, ApiResponse } from "../interfaces/api.interface";
import { HttpError } from "../enum/error.enum";

const useQuery = function <T>(
  url: string,
  options?: RequestInit
): ApiResponse<T[]> {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  useEffect(() => {
    // TODO Improve this condition
    if (url.includes("https://")) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await fetch(url, options);
          if (!response.ok) {
            if (response.status === 401) {
              throw new Error(HttpError.UNAUTHORIZE);
            }
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
          setData(data);
          setIsLoading(false);
        } catch (error) {
          setIsError(true);
          setError(error as ApiError);
          setIsLoading(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return {
    data,
    isLoading,
    isError,
    error,
  };
};

export default useQuery;
